/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-19 14:00:47
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-03-13 10:59:22
 */
import './set-public-path';
import Vue from 'vue';
import AvocadoLoadVue from 'avocado-load-vue';
import has from 'lodash/has';
import forEach from 'lodash/forEach';
import packageConfig from '../package.json';
import router from './router';
import App from './App.vue';

Vue.config.productionTip = false;

// 添加Vuex支持
// Vue.use(Vuex);

// 设置路由生命周期方法
if (has(Vue.prototype, '$routerHook')) {
  forEach(Vue.prototype.$routerHook, (item, index) => {
    router[index](item);
  });
}

// 使用Avocado创建生命周期对象
const vueLifecycles = AvocadoLoadVue({
  Vue,
  rootDomId: 'views',
  // 应用节点名称
  appName: packageConfig.name,
  appOptions: {
    render: h => h(App),
    router,
  },
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
export const { update } = vueLifecycles;
