export default [
  {
    //基础信息配置
    path: '/restaurantorder/hotel/baseInfoConfig',
    name: 'baseInfoConfig',
    meta: {
      permissionCode: 'el-hotel-ui-baseInfoConfig',
      routerType: 3,
    },
    component: () => import('../views/hotel/baseInfoConfig.vue'),
  },
  {
    //类别配置
    path: '/restaurantorder/hotel/typeConfig',
    name: 'typeConfig',
    meta: {
      permissionCode: 'el-hotel-ui-typeConfig',
      routerType: 3,
    },
    component: () => import('../views/hotel/typeConfig.vue'),
  },
  {
    //类别配置一级
    path: '/restaurantorder/hotel/typeFirstCategory',
    name: 'typeFirstCategory',
    meta: {
      permissionCode: 'el-hotel-ui-typeFirstCategory',
      routerType: 3,
    },
    component: () => import('../views/hotel/typeFirstCategory.vue'),
  },
  {
    //类别配置二级
    path: '/restaurantorder/hotel/typeSecondCategory',
    name: 'typeSecondCategory',
    meta: {
      permissionCode: 'el-hotel-ui-typeSecondCategory',
      routerType: 3,
    },
    component: () => import('../views/hotel/typeSecondCategory.vue'),
  },
  {
    //菜品管理
    path: '/restaurantorder/hotel/dishesManage',
    name: 'dishesManage',
    meta: {
      permissionCode: 'el-hotel-ui-dishesManage',
      routerType: 3,
    },
    component: () => import('../views/hotel/dishesManage.vue'),
  },
  {
    //选择菜品
    path: '/restaurantorder/hotel/dishesChoose',
    name: 'dishesChoose',
    meta: {
      permissionCode: 'el-hotel-ui-dishesChoose',
      routerType: 3,
    },
    component: () => import('../views/hotel/dishesChoose.vue'),
  },
  {
    //编辑描述
    path: '/restaurantorder/hotel/dishesUpdate',
    name: 'dishesUpdate',
    meta: {
      permissionCode: 'el-hotel-ui-dishesUpdate',
      routerType: 3,
    },
    component: () => import('../views/hotel/dishesUpdate.vue'),
  },
];
