import Router from 'vue-router';

import groupRouter from './grouprouter';
import hotelRouter from './hotelrouter';

const RouterView = {
  template: '<div><router-view></router-view></div>',
};


const routes = [{
  path: '/restaurantorder',
  component: RouterView,
  children: [
    ...groupRouter,
    ...hotelRouter,
  ],
}];

export default new Router({
  mode: 'history',
  routes,
});
