export default [
  {
    //酒店餐厅管理
    path: '/restaurantorder/group/hotelrestaurant',
    name: 'hotelrestaurant',
    meta: {
      permissionCode: 'el-group-ui-hotelrestaurant',
      routerType: 2,
    },
    component: () => import('../views/group/hotelrestaurant.vue'),
  },
];
